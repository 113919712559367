import React, { Component } from "react";
import { Image, Video, Transformation } from "cloudinary-react";
import TimezoneSelect from 'react-timezone-select'
import Modal from "react-modal";
import styles from "./UserProfile.module.scss";
import userProfileAndRegistration from "./UserProfileAndRegistration.module.scss";

export default class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      surname: "",
      jobTitle: "",
      country: "",
      company: "",
      areasOfInterest: "",
      email: "",

      userProfilePictureID: null,
      fileInputDisabled: false,

      userLanguage: "en",
      userTimezone: { value: "Europe/London" },
      userAgeBracket: 2,

      page1Active: true,
      page1Complete: false,
      page2Active: false,
      page2Complete: false,
      page3Active: false,
      page3Complete: false,
      page4Active: false,
      page4Complete: false,
      page5Active: false,
    };

    this.profileScrollRef = React.createRef();
  }

  componentDidMount () {
    this.retrieveUserDetails();
  }

  retrieveUserDetails = () => {
    fetch("/api/getUserProfile")
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.status);
        } else {
          return res.json();
        }
      })
      .then((data) => this.loadResponseIntoState(data))
      .catch((error) => {
        console.log("Unable to load user profile");
      });
  };

  loadResponseIntoState (data) {
    this.setState({
      firstName: data.user.firstName,
      surname: data.user.surname,
      jobTitle: data.user.jobTitle,
      country: data.user.country,
      company: data.user.company,
      areasOfInterest: data.user.areasOfInterest,
      email: data.user.email,
      userProfilePictureID: data.user.userProfilePictureID,
      userLanguage: data.user.userLanguage,
      userTimezone: data.user.userTimezone,
      userAgeBracket: data.user.userAgeBracket,

      // Reset dialogue status too
      page1Active: true,
      page1Complete: false,
      page2Active: false,
      page2Complete: false,
      page3Active: false,
      page3Complete: false,
      page4Active: false,
      page4Complete: false,
      page5Active: false,
    });
  }

  handleProfilePictureUpload = (event) => {
    this.setState({ fileInputDisabled: true });
    const data = new FormData();
    data.append("file", event.target.files[0]);

    fetch("/api/uploadImage", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          userProfilePictureID: data.publicID,
        });
        console.log("Returned upload ID: " + this.state.userProfilePictureID);
        this.setState({ fileInputDisabled: false });
      });
  };

  handleProfileVideoUpload = (event) => {
    this.setState({ fileInputDisabled: true });
    const data = new FormData();
    data.append("file", event.target.files[0]);

    fetch("/api/uploadVideo", {
      method: "POST",
      body: data,
    })
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          userProfileVideoID: data.publicID,
        });
        console.log("Returned upload ID: " + this.state.userProfilePictureID);
        this.setState({ fileInputDisabled: false });
      });
  };


  handleChange = (event) => {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    });
  };

  saveFormData = () => {
    fetch("/api/updateUserProfile", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.status === 200) {
          console.log("Saved successfully");
        } else {
          const error = new Error(res.error);
          throw error;
        }
      })
      .catch((err) => {
        console.error(err);
        alert("Error updating profile -  please try again");
      });
  };

  nextPage1 = () => {
    if (this.state.email && this.state.email.length > 0) {
      this.saveFormData();
      if (this.state.firstName.length < 1) {
        alert("Please enter your first name");
      } else if (this.state.surname.length < 1) {
        alert("Please enter your surname");
      } else if (this.state.jobTitle.length < 1) {
        alert("Please enter your job title");
      } else if (this.state.company.length < 1) {
        alert("Please enter your group");
      } else if (this.state.country.length < 2) {
        alert("Please enter your office location");
      } else if (this.state.email.length < 1) {
        alert("Please enter your email");
      } else {
        this.profileScrollRef.scrollTop = 0;
        this.setState({ page1Active: false, page2Active: true });
      }
    } else {
      alert("Email cannot be blank")
    }
  };

  nextPage2 = () => {
    this.saveFormData();
    this.profileScrollRef.scrollTop = 0;
    this.setState({ page2Active: false, page3Active: true });
    if (
      this.state.userProfilePictureID &&
      this.state.userProfilePictureID.length > 0 //&&
      //this.state.userProfileVideoID &&
      //this.state.userProfileVideoID.length > 0
    ) {
      this.setState({ page2Complete: true });
    } else {
      this.setState({ page2Complete: false });
    }
  };

  closeAndResetProfileModal = () => {
    this.setState({ page1Complete: false });
  };

  completeProfileChanges = () => {
    this.props.closeProfileModal();
    // This is the switch for whether to direct to holding page after registration
    window.location.href = "/holding";
  }

  render () {
    return (
      <Modal
        onAfterOpen={this.retrieveUserDetails}
        isOpen={this.props.showProfileModal}
        ariaHideApp={false}
        contentLabel="User Profile"
        //contentRef={(contentDiv) => (this.profileScrollRef = contentDiv)}
        overlayClassName={styles.modalOverlay}
        className={styles.modalContentShort}
        style={{ content: { backgroundImage: "url(" + window.$videoPath + "cornerNav/modalBackground.png)" } }}
      >
        <div className={styles.modalFlexboxColumn}>
          <div className={styles.modalTitleBar} style={{ backgroundImage: 'url(' + window.$videoPath + 'cornerNav/modalHeader.png)', }}>
            <h1 className={styles.modalPageTitle}>My Profile</h1>
            <button
                className={styles.modalClose}
                variant="success"
                onClick={() => this.props.closeProfileModal()}
              >
                Close
                <img
                  className={styles.modalCloseIcon}
                  src="/icons/close-no-circle.png"
                  alt="Close"
                />
              </button>
          </div>

          <div className={styles.scrollableArea} ref={(contentDiv) => (this.profileScrollRef = contentDiv)}>
            <div className={styles.profileEntryArea}>
              <br />
              {/*Page 1 */}
              {this.state.page1Active ? (
                <div>
                  <div className={userProfileAndRegistration.profileGridContainer}>

                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="firstName" className={userProfileAndRegistration.formLabel}>
                        First name:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        type="text"
                        name="firstName"
                        placeholder="First name"
                        value={this.state.firstName}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="surname" className={userProfileAndRegistration.formLabel}>
                        Surname:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        type="text"
                        name="surname"
                        placeholder="Surname"
                        value={this.state.surname}
                        onChange={this.handleChange}
                      />
                    </div>

                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="jobTitle" className={userProfileAndRegistration.formLabel}>
                        Job title:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        type="text"
                        name="jobTitle"
                        placeholder="Job title"
                        value={this.state.jobTitle}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="company" className={userProfileAndRegistration.formLabel}>
                        Group:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        type="text"
                        name="company"
                        placeholder="Group"
                        value={this.state.company}
                        onChange={this.handleChange}
                      />
                    </div>
                    {/**<div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="areasOfInterest" className={userProfileAndRegistration.formLabel}>
                        Areas of interest:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.longFormField} ${styles.textEntry}`}
                        type="text"
                        name="areasOfInterest"
                        placeholder="Areas of Interest"
                        value={this.state.areasOfInterest}
                        onChange={this.handleChange}
                      />
              </div>*/}


                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="country" className={styles.formLabel}>
                        Office location:
                      </label>
                      <select
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        style={{ height: "30px" }}
                        name="country"
                        tabIndex="50"
                        value={this.state.country}
                        onChange={this.handleChange}
                      >
                        <option value="-">Office location</option>
                        <option value="Austin">Austin</option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="Boston">Boston</option>
                        <option value="Bristol">Bristol</option>
                        <option value="Budapest">Budapest</option>
                        <option value="Cambridge">Cambridge</option>
                        <option value="Chandler">Chandler</option>
                        <option value="Copenhagen">Copenhagen</option>
                        <option value="Galway">Galway</option>
                        <option value="Grasbrunn">Grasbrunn</option>
                        <option value="Hsinchu">Hsinchu</option>
                        <option value="Lund">Lund</option>
                        <option value="Manchester">Manchester</option>
                        <option value="Noida">Noida</option>
                        <option value="Oslo">Oslo</option>
                        <option value="Ra’anana">Ra’anana</option>
                        <option value="Raleigh">Raleigh</option>
                        <option value="Remote Worker">Remote Worker</option>
                        <option value="San Jose">San Jose</option>
                        <option value="San Diego">San Diego</option>
                        <option value="Sentjernej">Sentjernej</option>
                        <option value="Shanghai">Shanghai</option>
                        <option value="Sheffield">Sheffield</option>
                        <option value="Shin-Yokohama">Shin-Yokohama</option>
                        <option value="Sophia">Sophia</option>
                        <option value="Taipei">Taipei</option>
                        <option value="Trondheim">Trondheim</option>
                      </select>
                    </div>

                    <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="email" className={userProfileAndRegistration.formLabel}>
                        Email:
                      </label>
                      <input
                        className={`${userProfileAndRegistration.formField} ${styles.textEntry}`}
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                    </div>
                    {/**<div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="userLanguage" className={userProfileAndRegistration.formLabel}>
                        Preferred Language:
                      </label>
                      <select
                        className={`${userProfileAndRegistration.formField} ${styles.dropDownEntry}`}
                        id="userLanguage"
                        name="userLanguage"
                        placeholder="Preferred Language"
                        tabIndex="90"
                        value={this.state.userLanguage}
                        onChange={this.handleChange}
                      >
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                        <option value="fr">French</option>
                        <option value="zh">Mandarin</option>
                      </select>
                    </div>

                       <div className={userProfileAndRegistration.profileGridElement}>
                      <label htmlFor="userAgeBracket" className={userProfileAndRegistration.formLabel}>
                        Age:
                      </label>
                      <select
                        className={`${userProfileAndRegistration.formField} ${styles.dropDownEntry}`}
                        id="userAgeBracket"
                        name="userAgeBracket"
                        placeholder="Age"
                        tabIndex="90"
                        value={this.state.userAgeBracket}
                        onChange={this.handleChange}
                      >
                        <option value="1">16/17</option>
                        <option value="2">18+</option>
                      </select>
                    </div>*/}

                    <div className={userProfileAndRegistration.profileGridElement} >
                      <label htmlFor="userTimezone" className={userProfileAndRegistration.formLabel}>
                        Timezone:
                      </label>
                      <TimezoneSelect
                        id="userTimezone"
                        value={this.state.userTimezone.value}
                        onChange={(data) => this.setState({ userTimezone: data })} />
                    </div>


                    <div className={userProfileAndRegistration.profileGridElement} style={{ marginTop: "40px", float: "right", marginBottom: "300px" }}>
                      <button
                        className={styles.ctaButton}
                        onClick={this.nextPage1}
                        disabled={this.state.fileInputDisabled}
                        style={{ float: "right" }}
                      >
                        Next
                      </button>
                    </div>
                  </div>


                  {/**<div className={styles.buttonArea}>
                    <Button
                      onClick={this.nextPage1}
                      className={`${"btn btn-success"} ${styles.bottomRightButtons}`}
                    >
                      Next
                    </Button>
                    <Button
                      onClick={this.props.closeProfileModal}
                      className={`${"btn btn-primary"} ${styles.bottomRightButtons}`}
                    >
                      Close without saving
                    </Button>
                  </div>*/}

                </div>
              ) : null}

              {/*Page 2 */}
              {this.state.page2Active ? (
                <div>
                  <div className={styles.profilePictureArea}>
                    <img
                      className={styles.profilePictureBackground}
                      src={window.$videoPath + "icons/profilePictureBackground.png"}
                      alt="Detail"
                    />
                    <Image
                      className={styles.profilePicture}
                      cloudName="oijqec973f1nf"
                      publicId={this.state.userProfilePictureID + ".png"}
                      width="180"
                      height="180"
                      secure={true}
                    >
                      <Transformation
                        height="180"
                        width="180"
                        gravity="face"
                        crop="fill"
                        radius="max"
                      />
                    </Image>
                  </div>







                  <p className={styles.subheading}>
                    Profile picture upload
                  </p>
                  <label
                    className={styles.formLabel}
                    htmlFor="profilePicture"
                    style={{ display: 'block' }}
                  >
                    You can change this at any point later.<br />
                    Picture upload (JPEG, PNG, GIF), max. file size of 2MB<br />
                  </label>
                  <br />
                  <label className={styles.customFileUpload}>
                    <div className={styles.orangeFeatureButton} style={{ textAlign: 'center' }}>Upload</div>
                    <input
                      className={styles.fileInput}
                      name="profilePicture"
                      type="file"
                      disabled={this.state.fileInputDisabled}
                      accept="image/*"
                      onChange={this.handleProfilePictureUpload}
                    />
                  </label>

                  <div className={styles.profileVideoSelection}>
                    <label className={styles.formLabel} htmlFor="profileVideo">
                      Video upload
                    </label>
                    <p className={styles.introText}>
                      Please upload a video (up to 30 secs) telling people a bit
                      about yourself.
                      <br />
                      <br />
                      Feel free to be as creative as you like with this!
                      MP4/WMV/MOV video files are all good
                    </p>
                    <label className={styles.customFileUpload}>
                      <img
                        className={styles.uploadButton}
                        src={window.$videoPath + "icons/profile/upload.png"}
                        alt="Upload"
                      ></img>
                      <input
                        className={styles.fileInput}
                        name="profileVideo"
                        type="file"
                        disabled={this.state.fileInputDisabled}
                        accept="video/*"
                        onChange={this.handleProfileVideoUpload}
                      />
                    </label>
                    {this.state.userProfileVideoID != null ? (
                      <div>
                        <Video
                          controls={true}
                          cloudName="oijqec973f1nf"
                          publicId={this.state.userProfileVideoID}
                          width="200"
                        ></Video>
                      </div>
                    ) : null}
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />


                  <div className={styles.nextBackButtonsRow}>
                    {/**<button
                      className={styles.button}
                      onClick={this.props.closeProfileModal}
                      type="button"
                    >
                      Close
                    </button>*/}
                    <div style={{ flex: 1 }} />
                    <button
                      className={styles.ctaButton}
                      onClick={this.nextPage2}
                      disabled={this.state.fileInputDisabled}
                    >
                      Next
                    </button>
                  </div>

                </div>
              ) : null}

              {/*Page 3*/}
              {this.state.page3Active ? (
                <div>
                  <p className={styles.subheading}>You’re almost there!</p>
                  <p className={styles.subheading} style={{ fontSize: "1.2em" }}>

                    <br />
                    Thank you for completing your profile but don't forget you can amend it at any time before the end of the event. To amend your profile just head back to ‘My Profile’ section at any time.<br /><br />
                    To save and close this window click on the ‘Save & Close’ button below.
                  </p>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <div className={styles.nextBackButtonsRow}>
                    <div style={{ flex: 1 }} />
                    <button
                      className={styles.ctaButton}
                      onClick={this.completeProfileChanges}
                      disabled={this.state.fileInputDisabled}
                    >
                      Save & Close
                    </button>
                  </div>
                  <br />

                  <br /> <br />
                  <br />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
