import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Link } from "react-router-dom";
import { dateFormatterTimeOnly } from "../helpers/dateFormatter.js";
import Button from "react-bootstrap/Button";
import styles from "./CustomLogin.module.scss";

export default class RegistrationPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirectToHome: false,
    };
  }

  // Required to initially set the show hide profile status
  componentDidMount () {
    console.log("Show profile status: " + this.props.showProfileOnLogin);
    {/**if (this.props.showProfileOnLogin === 1) {
      this.props.setShowProfileModal(true);
      console.log("true");
    } else {
      this.props.setShowProfileModal(false);
      console.log("false");
    }*/}
    this.props.setShowProfileModal(true);
  }

  // Required  to trigger the show or hide if already on register page and status changes
  componentDidUpdate (previousProps) {
    // Only action profile change if props have changed to avoid endless loop
    if (previousProps.showProfileOnLogin !== this.props.showProfileOnLogin) {
      console.log("Show profile status: " + this.props.showProfileOnLogin);
      if (this.props.showProfileOnLogin === 1) {
        this.props.setShowProfileModal(true);
        console.log("true");
      } else {
        this.props.setShowProfileModal(false);
        console.log("false");
      }
    }
  }


  render () {
    const redirectToHome = this.state.redirectToHome;
    if (redirectToHome === true) {
      return (
        <div>
          <Redirect to="/home" />
        </div>
      );
    } else {
      return (
        <div>
          <div className={styles.proportionalDiv}>
            <img
              className={styles.backgroundImage}
              src={this.props.showMobile ? window.$videoPath + "customLoginPage/loginBackgroundMobile.jpg" : window.$videoPath + "customLoginPage/loginBackgroundFull.jpg"}
              alt="Background"
            ></img>
            <img
              className={styles.armWhiteLogo}
              src={window.$videoPath + "customLoginPage/smallLogo.png"}
              alt="Arm logo"
            />
            <div className={styles.loginBox}>
              <br />
              <br />
              <img
                className={styles.lockUpWhite}
                src={window.$videoPath + "customLoginPage/logoFromDark.png"}
                alt="Arm logo"
              />
              <div className={styles.loginTextEntry}>
                <h2 className={styles.signInText}>
                  Thank you for completing registration.
                  <br />
                  {/**<br /> We look forward to seeing you on Tuesday 22nd March at 07:45 GMT*/}
                  <br /> The event will begin on Tuesday 17 September at {this.props.userProfile ? (dateFormatterTimeOnly(new Date("2024-09-17T10:30:00.000+01:00"), this.props.userProfile)) : ""}
                  {/**<br /> The event will begin on Tuesday 8th June at 07:45 BST / 12:15 IST / 15:45 JST.*/}
                  {/**<br /> We look forward to seeing you on Tuesday 8th June at 07:45 BST / 12:15 IST / 15:45 JST*/}
                  {/**<br /> We look forward to seeing you on Tuesday 14th December at 07:45 GMT*/}
                  {/**<br /> Unfortunately, the December event has been postponed. Please look out for an email from Internal Comms with new dates.*/}
                </h2>
                <br />
                <a href={"/holding"}>
                  <Button
                    style={{ position: "absolute", right: "12%", bottom: "150px" }}
                  >
                    Join Event
                  </Button>
                </a>
                <Button
                  variant="success"
                  style={{ position: "absolute", left: "12%", bottom: "150px" }}
                  onClick={() => this.props.setShowProfileModal(true)}
                >
                  Edit Profile
                </Button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
